import { createUseStyles } from "react-jss";

const useStyles: any = createUseStyles({
  arrowBackSection: {
    display: "flex",
    alignItems: "center",
  },
  txtChangePassword: {
    marginLeft: "50px",
  },
  row: {
    display: "flex",
    height: "80px",
  },
  leftContent: {
    flex: "2",
  },
  rowTitle: {
    display: "flex",
    flexDirection: "row",
    padding: "15px 0 0",
    fontWeight: "700",
    color: "#9b9b9b",
    flex: 1,
    marginRight: "22px",

  },

  titleLabel: {
    marginLeft: "5px",
    textTransform: "uppercase",

  },

  rowValue: {
    marginLeft: "22px",
    color: "#2245a9",
    marginRight: "22px",
  },

  rowContent: {
    marginLeft: "22px",
    color: "#3C3C3E",
  },
  btnSave: {
    display: "flex",
    width: "40%",
    height: "50px",
    marginTop: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "50px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2245a9",
    borderRadius: "10px",
    border: "none",
    color: "white",
    fontSize: "20px",
    fontWeight: "600",
  },

  confimrPassword: {
    color: "red",
    fontWeight: "500",
    fontStyle: "italic",
    marginLeft: "auto",
    textAlign: 'end'
  },
  password: {
    color: "red",
    fontWeight: "500",
    fontStyle: "italic",
    marginLeft: "auto",
  },

  confirmContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "20px",
    color: "#2245a9",
    alignItems: "center",
  },
  buttonCancel: {
    display: "flex",
    marginTop: "20px",
    padding: "10px 20px",
    fontWeight: "700",
    borderRadius: "7px",
    color: "#2245a9",
    backgroundColor: "#fff",
    border: "1px solid #2245a9",
    outline: "none",
    cursor: "pointer",
  },
});

export default useStyles;
