import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "stores/models";
import useStyles from "./styles";
import { UsersAPI } from "stores/apis";
import { Link } from "react-router-dom";

const HeaderLogout = (props: any) => {
  const styles = useStyles();
  const { t }: any = useTranslation();

  const { salon, user } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    user: state.users.user,
  }));

  const onOpenAddress = () => {
    window.open(
      `https://maps.google.com/?q=${salon?.location?.latitude},${salon?.location?.longitude}`
    );
  };

  const [filebase64, setFileBase64] = useState<string>("");

  const convertFile = (files: FileList | null) => {
    if (files) {
      const fileRef = files[0] || "";
      const fileType: string = fileRef.type || "";
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev: any) => {
        // convert it to base64
        setFileBase64(`data:${fileType};base64,${btoa(ev.target.result)}`);
      };

      updateAvatar(fileRef);
    }
  };

  const updateAvatar = async (fileRef: any) => {
    const response = await UsersAPI.updateUser({
      userId: user?.id,
      files: fileRef,
      name: user?.name,
      phone: user?.phone,
    });
    if (response.data.status === 200) {
      console.log("line 50", response.data);
    }
  };

  return (
    <div className={styles.sectionHeader}>
      <div className={styles.sectionSalon}>
        <div className={styles.sectionImages}>
          <div className={styles.salonAvatar}>
            <img
              className={styles.salonImage}
              src={salon?.logoUrl}
              alt="avatar"
            />
          </div>
        </div>
        <div className={styles.sectionSalonInfo}>
          <div className={styles.salonName}>{salon?.name}</div>
          <div className={styles.salonAddress} onClick={onOpenAddress}>
            {salon?.location?.address}
          </div>
          <div className={styles.salonPhone}>{salon?.phone}</div>
        </div>
      </div>

      <div className={styles.sectionSalon}>
        <div className={styles.sectionUser}>
          <div className={styles.sectionUserInfo}>
            <div className={styles.userName}>{user?.name}</div>
            <div className={styles.userEmail}>{user?.email}</div>
            <div className={styles.userPhone}>{user?.phone}</div>
          </div>
        </div>
        <div className={styles.sectionImages}>
          <div className={styles.salonAvatar}>
            <label htmlFor="file-input" className={styles.fileLabel}>
              <Link to={`/user`}>
                <img
                  className={styles.salonImage}
                  src={user?.avatarUrl}
                  alt="avatar"
                />{" "}
              </Link>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLogout;
