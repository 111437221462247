import * as actions from "stores/actions";

import { Loading } from "components/loading";
import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SalonAPI } from "stores/apis";
import { ReduxState } from "stores/models";
import SalonModel from "stores/models/salon.models";
import { localStorageUtils } from "utils/index";
import { LocalStorageValueTypes } from "utils/localStorage";
import { modalRef } from "utils/refs";
import Menu from "./components/menu";
import Modal from "./components/modal";
import useStyles from "./styles";

interface WrapperProps {
  children: React.ReactNode;
  className?: string;
  loadingDisabled?: boolean;
}

export const Wrapper = (props: WrapperProps) => {
  const { children, className, loadingDisabled } = props;
  const { isLoading, user, salon } = useSelector((state: ReduxState) => ({
    isLoading: state.users.isLoading,
    user: state.users.user,
    salon: state.salons.salon,
  }));

  const { refCode } = useParams();
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getSalon = async () => {
    if (refCode && (!salon || salon.reference_code !== refCode)) {
      const curSalon = await SalonAPI.getSalonWeb(refCode);
      dispatch({
        type: actions.SET_CURRENT_SALON,
        payload: new SalonModel(curSalon.data.data),
      });
    }
  };

  useEffect(() => {
    getSalon();
  }, []);

  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.UserLanguage
    );
    if (lang) {
      i18next.changeLanguage(lang);
    }
  }, []);

  if (isLoading && !loadingDisabled) return <Loading />;

  return (
    <div className={`${styles.containers} ${className}`}>
      {/* <Header /> */}
      <div className={styles.content}>{children}</div>
      {user ? (
        <div>
          {" "}
          <Menu />
        </div>
      ) : (
        ""
      )}
      <Modal ref={modalRef} />
    </div>
  );
};
