import "./styles.css";

import * as actions from "stores/actions";

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthAPI, UsersAPI } from "stores/apis";

import images from "assets";
import { Wrapper } from "components";
import { Loading } from "components/loading";
import { envConfigs } from "configs/env.config";
import i18next from "i18next";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import gateway from "stores/apis/gateway";
import { ReduxState } from "stores/models";
import UserModel from "stores/models/user.models";
import { localStorageUtils } from "utils";
import { LocalStorageValueTypes } from "utils/localStorage";

export enum Language {
  English = "en",
  Vietnamese = "vi",
}

interface InputState {
  loading: boolean;
  username: string;
  password: string;
  error: any;
  lang: Language;
}

const Login = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { refCode } = useParams();

  const [state, setState] = useState<InputState>({
    loading: false,
    username: "",
    password: "",
    error: "",
    lang: Language.English,
  });
  const dispatch = useDispatch();
  const handleStateChange = (
    value: string | string[] | boolean,
    field: string
  ) => setState((prevState) => ({ ...prevState, [field]: value }));

  const { salon } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
  }));

  const saveUser = (data: any) => {
    dispatch({ type: actions.SET_USER, payload: new UserModel(data) });
    // localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserData, JSON.stringify(data))

    // localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, data.Language)
    // i18next.changeLanguage(data?.Language)
  };

  const saveRefCode = () => {
    if (refCode) {
      localStorageUtils.setLocalStorage(
        LocalStorageValueTypes.SalonRefCode,
        refCode
      );
    }
  };

  const authLogin = async () => {
    // get user by token
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken
    );
    if (!token) return false;

    gateway.init(JSON.parse(token));
    const response: any = await UsersAPI.getUserProfile();
    const { data } = response;

    if (data?.isSuccess) {
      dispatch({ type: actions.GET_TIMEZONES_REQUEST });
      saveUser(data?.data);
    }
    return data?.isSuccess;
  };

  const checkAuthorized = async () => {
    const isLogin = await authLogin();

    if (isLogin) {
      window.location.href = `${envConfigs.WEB_URL}`;
    }
  };

  useEffect(() => {
    saveRefCode();
    checkAuthorized();
  }, []);

  const onLogin = async () => {
    handleStateChange("", "error");
    handleStateChange(true, "loading");

    const { username, password } = state;

    const response: any = await AuthAPI.login({
      username,
      password,
    });
    const isSuccess = response?.data?.isSuccess || false;
    const data = response?.data?.data || null;

    if (isSuccess && data) {
      localStorageUtils.setLocalStorage(
        LocalStorageValueTypes.AccessToken,
        JSON.stringify(data?.access_token)
      );
      if (refCode) return navigate(`/${refCode}`);

      return navigate(`/`);
    } else if (response?.data.data?.message) {
      handleStateChange(response?.data.data?.message, "error");
    }

    handleStateChange(false, "loading");
  };

  const onChangeInput = (e: any) => {
    const { name, value } = e.target;
    handleStateChange(value, name);
  };

  const onSwitchingLanguage = () => {
    const newLang = state.lang === "vi" ? "en" : "vi";
    handleStateChange(newLang, "lang");
    i18next.changeLanguage(newLang);
    moment.locale(newLang);
    localStorageUtils.setLocalStorage(
      LocalStorageValueTypes.UserLanguage,
      newLang
    );
  };

  const disabled = !state.username || !state.password || state.loading;

  return (
    <Wrapper>
      <div className="container">
        {state.loading && <Loading />}
        {/* <Link to='#' className="languageSection" onClick={onSwitchingLanguage}>
          {state.lang === 'en' ? <span className={`flag-icon flag-icon-gb`}></span> : <span className={`flag-icon flag-icon-vn`}></span>}
          </Link> */}
        <div className="content">
          <div className="logo-box">
            <img src={salon?.logoUrl ?? images.logoKay()} alt="logo" />
          </div>
          <div className="login-page">
            <div className="header-section">
              <p className="txtText">{t("welcome_back")}</p>
              <p className="txtTextDescription">
                {t("login_with_your_account")}
              </p>
            </div>

            <div className="error text-red text-center">{state.error}</div>

            <form
              className="form-login"
              method="POST"
              onSubmit={props.handleSubmit}
            >
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="username"
                  placeholder="Email"
                  value={state.username}
                  onChange={onChangeInput}
                />
              </div>

              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder={t("password")}
                  value={state.password}
                  onChange={onChangeInput}
                />

                <div className="forgot-password">
                  <Link
                    to={`/forgot-password`}
                    className="forgot-password-text"
                  >
                    {t("forgot_password")}?
                  </Link>
                </div>
              </div>

              <div className="form-group text-center">
                <button
                  type="button"
                  className={`btn btn-login ${disabled ? "btn-disabled" : ""}`}
                  onClick={disabled ? undefined : onLogin}
                >
                  {t("login")}
                </button>
              </div>

              <div className="sign-up">
                <Link to={`/sign-up`} className="sign-up-text">
                  {t("Don't have an account?")} <b>Sign up</b>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Login;
