import * as actions from "stores/actions";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IoArrowBackSharp, IoLockClosedOutline } from "react-icons/io5";
import { Wrapper } from "components";
import { ReduxState } from "stores/models";
import { modalRef } from "utils/refs";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import { UsersAPI } from "stores/apis";

const Success = React.memo(() => {
  const styles = useStyles();
  const onCloseModal = () => {
    modalRef?.current?.close();
  };
  return (
    <div className={styles.confirmContent}>
      <h4>Successfully Changed Password</h4>
      <button className={styles.buttonCancel} onClick={onCloseModal}>
        Close
      </button>
    </div>
  );
});

const ChangePassword = () => {
  const history = useNavigate();
  const styles = useStyles();

  const [state, setState] = useState({
    password: "",
    newpassword: "",
    confirmNewPassword: "",
    msgWrongPassword: "",
    msgWrongConfirmPassword: "",
  });

  const { salon, user } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    user: state.users.user,
  }));
  const handleStateChange = (
    value: string | string[] | boolean,
    field: string
  ) => setState((prevState) => ({ ...prevState, [field]: value }));

  const onChangeInput = (e: any) => {
    const { name, value } = e.target;
    handleStateChange(value, name);
    if (name === "confirmNewPassword") {
      handleStateChange("", "msgWrongConfirmPassword");
    }
    if (name === "password") {
      handleStateChange("", "msgWrongPassword");
    }
  };

  const onChangePassword = (e: any) => {
    if (state.msgWrongPassword) {
      handleStateChange("", "msgWrongPassword");
    }
    const { name, value } = e.target;
    handleStateChange(value, name);
  };

  const onChangeConfirmPassword = (e: any) => {
    if (state.msgWrongConfirmPassword) {
      handleStateChange("", "msgWrongConfirmPassword");
    }
    const { name, value } = e.target;
    handleStateChange(value, name);
  };

  const openModalSuccess = () => {
    modalRef?.current?.open({
      content: <Success />,
    });
  };

  const onUpdatePassword = async () => {
    if (state.newpassword !== state.confirmNewPassword) {
      throw handleStateChange(
        "Confirm Password Invalid!",
        "msgWrongConfirmPassword"
      );
    }
    if (user) {
      const res = await UsersAPI.changePasswordUser(user.id, {
        currentPassword: state.password,
        newPassword: state.newpassword,
      });
      if (res.data.status !== 200) {
        throw handleStateChange("Password Invalid!", "msgWrongPassword");
      }
      openModalSuccess();
    }
  };

  return (
    <Wrapper>
      <div>
        <div className={styles.arrowBackSection}>
          <IoArrowBackSharp
            size={40}
            color="grey"
            cursor="pointer"
            onClick={() => {
              history(-1);
            }}
          />
          <div className={styles.txtChangePassword}>
            <h3>Change Password</h3>
          </div>
        </div>
        <div>
          <div className={styles.row}>
            <div className={styles.leftContent}>
              <div className={styles.rowTitle}>
                <IoLockClosedOutline size={18} />
                <div className={styles.titleLabel}>Password</div>
                {state.msgWrongPassword ? (
                  <div className={styles.password}>
                    {state.msgWrongPassword}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.rowValue}>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={state.password}
                  onChange={onChangePassword}
                />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.leftContent}>
              <div className={styles.rowTitle}>
                <IoLockClosedOutline size={18} />
                <div className={styles.titleLabel}>New Password</div>
              </div>
              <div className={styles.rowValue}>
                <input
                  type="password"
                  className="form-control"
                  name="newpassword"
                  value={state.newpassword}
                  onChange={onChangeInput}
                />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.leftContent}>
              <div className={styles.rowTitle}>
                <IoLockClosedOutline size={18} />
                <div className={styles.titleLabel}>Confirm New Password</div>
                {state.msgWrongConfirmPassword ? (
                  <div className={styles.confimrPassword}>
                    {state.msgWrongConfirmPassword}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.rowValue}>
                <input
                  type="password"
                  className="form-control"
                  name="confirmNewPassword"
                  value={state.confirmNewPassword}
                  onChange={onChangeConfirmPassword}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <button className={styles.btnSave} onClick={onUpdatePassword}>
            Change
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default ChangePassword;
