import { createUseStyles } from "react-jss";

const useStyles: any = createUseStyles({
  sectionBody: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "column",
  },

  row: {
    display: "flex",
    height: "80px",
    marginTop: "25px",
  },

  leftContent: {
    flex: "2",
  },
  firstSection: {
    display: "flex",
  },
  emailName: {
    flex: "4",
  },
  imageSection: {
    position: "relative",
    marginRight: "25px",
    width: "200px",
    '@media (max-width : 390px)' : {
      width: '150px'
    }
  },

  avatar: {
    position: "absolute",
    bottom: "-15px",
    right: "0",
  },

  avatarImage: {
    flex: "1",
    maxWidth: "200px",
    width: "200px",
    borderRadius: "20px",
    height: "200px",
    cursor: "pointer",
    '@media (max-width : 390px)' : {
      width: '150px'
    }

  },

  rowTitle: {
    display: "flex",
    flexDirection: "row",
    padding: "15px 0 0",
    fontWeight: "700",
    color: "#9b9b9b",
    flex: 1,
    marginRight: '25px'
  },

  titleLabel: {
    marginLeft: "5px",
    textTransform: "uppercase",
  },

  rowValue: {
    marginLeft: "22px",
    color: "#2245a9",
    marginRight: "22px",
    marginTop: "10px",
  },

  btnSave: {
    display: "flex",
    width: "40%",
    height: "50px",
    marginTop: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "50px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2245a9",
    borderRadius: "10px",
    border: "none",
    color: "white",
    fontSize: "20px",
    fontWeight: "600",
  },
  //#2245a9;

  fileInput: {
    display: "none",
  },

  confirmContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "20px",
    color: "#2245a9",
    alignItems: "center",
  },

  buttonClose: {
    display: "flex",
    marginTop: "20px",
    padding: "10px 20px",
    fontWeight: "700",
    borderRadius: "7px",
    color: "#2245a9",
    backgroundColor: "#fff",
    border: "1px solid #2245a9",
    outline: "none",
    cursor: "pointer",
  },

  message: {
    color: " red",
    fontWeight: "400",
    fontSize: "14px",
  },

  arrowBackSection: {
    display: "flex",
    alignItems: "center",
    color: "grey",
  },

  txtAccount: {
    marginLeft: "30px",
    marginTop: "5px",
    color: "black",
  },

  changePassword: {
    marginLeft: "auto",
  },
  btnChange: {
    border: "none",
    backgroundColor: "white",
    fontWeight: "800",
  },

  formControl: {
    backgroundColor: "rgb(230,230,230)",
    height: "50px",
    borderRadius: "10px",
    border: "1px solid white",
    paddingLeft: "10px",
    fontSize: "18px",
    fontWeight: "400",
    width: "100%",
  },
});

export default useStyles;
