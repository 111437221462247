import * as actions from 'stores/actions'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import images from 'assets'
import EmptyState from 'components/empty-state'
import Collapsible from 'containers/bookings/upsert-booking/components/choose-services/components/collapsible'
import { useTranslation } from 'react-i18next'
import { ReduxState } from 'stores/models'
import { SalonServiceStatus } from 'stores/models/service.models'
import useStyles from './styles'

interface State {
  servicesList: any[],
  selected: any,
  keywords: string,
}

const ChooseServices = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const styles = useStyles()
  const [state, setState] = useState<State>({
    servicesList: [],
    selected: props?.selected || [],
    keywords: ''
  })
  const handleStateChange = (value: any, field: string) =>
    setState(prevState => ({ ...prevState, [field]: value }))

  const { callback, onCloseModal } = props

  const { user, salon, categories, services } = useSelector((state: ReduxState) => ({
    user: state.users.user,
    salon: state.salons.salon,
    categories: state.categories.categories,
    services: state.services.services,
  }))

  useEffect(() => {
    dispatch({ type: actions.GET_CATEGORIES, payload: { salonId: salon?.id } })
    dispatch({ type: actions.GET_SERVICES, payload: { salonId: salon?.id, statuses: [SalonServiceStatus.Active].join(',') } })
  }, [user, salon])

  useEffect(() => {
    const newDataServices: any = categories.reduce(
      (values: any, category: any) => {
        values.push({
          category: category,
          data: services.filter((s: any) => s.categoryId === category.id) || []
        })
        return values
      },
      []
    )
    handleStateChange(newDataServices, 'servicesList')
  }, [categories, services])

  const onSelected = (service: any) => {
    const index = state.selected.findIndex((i: any) => i.id === service.id)
    if (index === -1) {
      setState(prevState => ({ ...prevState, selected: [...prevState.selected, service] }))
    } else {
      setState(prevState => ({ ...prevState, selected: prevState.selected.filter((i: any) => i.id !== service.id) }))
    }
  }

  const onSearchService = (e: any) => {
    const keywords = e.target.value
    if (keywords.length > 0) {
      const newDataServices: any = categories.reduce(
        (values: any, category: any) => {
          values.push({
            category: category,
            data:
              services.filter(
                (s: any) =>
                  s.categoryId === category.id &&
                  (s?.name?.toLowerCase()?.includes(keywords.toLowerCase()) ||
                    s?.description?.toLowerCase()?.includes(keywords.toLowerCase()))
              ) || []
          })
          return values
        },
        []
      )
      handleStateChange(newDataServices, 'servicesList')
    } else {
      const newDataServices: any = categories.reduce(
        (values: any, category: any) => {
          values.push({
            category: category,
            data:
              services.filter((s: any) => s.categoryId === category.id) || []
          })
          return values
        },
        []
      )
      handleStateChange(newDataServices, 'servicesList')
    }
    handleStateChange(keywords, 'keywords')
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        {t('choose_service')}
      </div>

      <div className="form-group">
        <input type="text" className="form-control" name="keywords" placeholder="Search" value={state.keywords} onChange={onSearchService} />
      </div>

      <div className={styles.modalList}>
        {state?.servicesList?.length > 0 ?
          state?.servicesList?.map((row: any) => {
            return <Collapsible key={row?.category?.id} row={row} selected={state.selected} onSelected={onSelected} />
          })
          :
          <EmptyState imgUrl={images.bookingEmpty()} text={t('no_services')} classNameImage={styles.noSelected} />
        }
      </div>
      <div className={styles.priceRefOnly}>({t('price_ref_only')})</div>
      <div className={styles.modalFooter}>
        <button className={styles.modalButtonClose} onClick={() => onCloseModal()}>
          <span>{t('close')}</span>
        </button>

        <button className={styles.modalButton} onClick={() => callback(state.selected)}>
          <span>{t('confirm')}</span>
        </button>
      </div>
    </div>
  )
}

export default ChooseServices
