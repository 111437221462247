import * as actions from "stores/actions";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BookingAPI } from "stores/apis";
import BookingModel, {
  BookingPromotionType,
  BookingStatus,
  BookingStatusNextNames,
} from "stores/models/booking.models";
import { formatDate, formatDateTz } from "utils/times";

import { AxiosResponse } from "axios";
import { Stars, Wrapper } from "components";
import { envConfigs } from "configs/env.config";
import { useTranslation } from "react-i18next";
import { AiOutlineBarcode, AiOutlineDollarCircle } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import {
  IoCalendarClearOutline,
  IoDocumentTextOutline,
  IoFileTrayFullOutline,
  IoListOutline,
} from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { ReduxState } from "stores/models";
import { formatCurrency } from "utils/currency";
import { localStorageUtils } from "utils/index";
import { LocalStorageValueTypes } from "utils/localStorage";
import { modalRef } from "utils/refs";
import BookingServiceCard from "./components/booking-service-card";
import ModalBookingRate from "./components/modal-booking-rate";
import useStyles from "./styles";

const Confirm = (props: any) => {
  const styles = useStyles();

  const { message, callback, cancelButtonText, confirmButtonText, t } = props;

  const onCloseModal = () => modalRef?.current?.close();

  return (
    <div className={styles.confirmContent}>
      <h6>{message}</h6>
      <div className={styles.groupButton}>
        <button onClick={onCloseModal} className={styles.buttonCancel}>
          {cancelButtonText ?? t("cancel")}
        </button>
        {callback && (
          <button onClick={callback} className={styles.buttonConfirm}>
            {confirmButtonText ?? t("confirm")}
          </button>
        )}
      </div>
    </div>
  );
};

const Booking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate: any = useNavigate();
  const styles = useStyles();

  const refCode = localStorageUtils.getLocalStorage(
    LocalStorageValueTypes.SalonRefCode
  );

  const { bookingId } = useParams();

  const { salon, bookings, booking, bookingComments, user } = useSelector(
    (state: ReduxState) => ({
      salon: state.salons.salon,
      bookings: state.bookings.bookings,
      booking: state.bookings.booking,
      bookingComments: state.bookings.bookingComments,
      user: state.users.user,
    })
  );

  const [valueRating, setValueRating] = useState(0);

  useEffect(() => {
    document.title = salon?.name ? salon.name : `KAY CLINIC`;
  }, [booking]);

  console.log(booking, "line 72");
  useEffect(() => {
    if (
      Number(booking?.status === 4) &&
      booking?.rate.value == null &&
      bookingId == booking?.id
    ) {
      modalRef?.current.open({
        content: <ModalBookingRate booking={booking} />,
      });
    }
    console.log(bookingId, booking?.id, 95);
  }, [booking?.id, booking?.status]);

  useEffect(() => {
    if (booking?.rate?.value) setValueRating(booking?.rate.value);
    console.log(booking?.rate?.value, 106);
  }, [booking?.rate.value]);

  useEffect(() => {
    getInfo(salon?.id);
  }, [user, salon?.id]);

  const getInfo = async (salonId: any) => {
    if (salonId) {
      dispatch({ type: actions.GET_BOOKINGS_REQUEST, payload: { salonId } });
      dispatch({ type: actions.GET_BOOKING_COMMENTS, payload: { bookingId } });
    }
  };

  useEffect(() => {
    if (user && salon && bookingId) {
      dispatch({ type: actions.GET_BOOKING_DETAIL, payload: bookingId });
    }
  }, [user, salon, bookingId]);

  if (!booking) return null;

  const disabledCancel = [
    BookingStatus.Canceled,
    BookingStatus.CheckedIn,
    BookingStatus.Done,
  ].includes(Number(booking?.status));
  const disabledCall = [BookingStatus.Canceled, BookingStatus.Done].includes(
    Number(booking?.status)
  );
  const subTotal =
    booking?.bookingServices?.reduce(
      (total: number, item: any) => total + item.price,
      0
    ) || 0;

  let promotionValue: any = 0;
  if (booking?.promotionType === BookingPromotionType.Percent) {
    promotionValue = booking?.promotionValue
      ? "-" + subTotal * (booking?.promotionValue / 100)
      : 0;
  } else {
    promotionValue = booking?.promotionValue
      ? "-" + booking?.promotionValue
      : 0;
  }

  let total = subTotal + (booking?.surcharge || 0);
  if (Number(booking?.promotionType) === BookingPromotionType.Percent) {
    total -= subTotal * (booking?.promotionValue / 100);
  } else {
    total -= booking?.promotionValue;
  }

  const bookingStatusTime = booking?.bookingStatuses?.find(
    (i: any) => Number(i?.status) === Number(booking?.status)
  );

  const confirmCancelBooking = (status: BookingStatus, params?: any) => {
    const message = t("are_you_want_to_this_booking", { status: t("decline") });
    modalRef?.current?.open({
      content: (
        <Confirm
          message={message}
          callback={() => updateBooking(status, params)}
          t={t}
        />
      ),
    });
  };

  const updateBooking = async (status: BookingStatus, params?: any) => {
    const data: any = {
      bookingId: Number(booking?.id),
      status,
    };

    let message = "";
    switch (status) {
      case BookingStatus.Canceled:
        message = t("reject_successfully");
        break;
      case BookingStatus.Confirmed:
        message = t("confirm_successfully");
        break;
      case BookingStatus.CheckedIn:
        message = t("check_in_successfully");
        break;
      case BookingStatus.Done:
        message = t("check_out_successfully");
        break;
      default:
        message = t("check_out_successfully");
        break;
    }

    const response: AxiosResponse<any> | undefined =
      await BookingAPI.updateBooking(data);
    if (response?.data?.status === 200) {
      const newBooking = new BookingModel(response.data.data);
      dispatch({
        type: actions.GET_BOOKING_DETAIL_SUCCESS,
        payload: newBooking,
      });

      const newBookings = [...bookings];
      const bIndex = newBookings.findIndex(
        (b: BookingModel) => b.id === newBooking.id
      );
      if (bIndex > -1) {
        newBookings[bIndex] = newBooking;
        dispatch({ type: actions.GET_BOOKINGS_SUCCESS, payload: newBookings });
      }

      modalRef?.current?.open({
        content: <Confirm message={message} cancelButtonText={"close"} t={t} />,
      });
    }
  };

  const callSalon = () => {
    window.location.href = `tel:${salon?.phone}`;
  };

  const onBack = () => {
    navigate(`/${refCode}/bookings`);
  };

  const stars = Array(5).fill(0);

  const onEditRating = () => {
    modalRef?.current.open({
      content: <ModalBookingRate booking={booking} />,
    });
  };

  console.log("line 229", booking);

  return (
    <Wrapper>
      <div className={styles.sectionHeader}>
        <div className={styles.rowSalonName}>
          <div className={styles.salonAvatar}>
            <img
              className={styles.salonImage}
              src={salon?.logoUrl}
              alt="avatar"
            />
          </div>
          <div className={styles.salonInfo}>
            <div className={styles.salonName}>{salon?.name}</div>
          </div>
        </div>
        <div className={styles.salonInfo2}>
          <div className={styles.salonPhone}>{salon?.phone}</div>
          <div className={styles.salonAddress}>{salon?.location?.address}</div>
        </div>
      </div>

      <div className={styles.sectionList}>
        {booking?.bookingCode && (
          <div className={styles.contentRow}>
            <div className={styles.rowLabel}>
              <AiOutlineBarcode size={20} />
              <span className={styles.label}>{t("booking_code")}</span>
            </div>
            <div className={styles.rowItemContent}>{booking?.bookingCode}</div>
          </div>
        )}

        <div className={styles.contentRow}>
          <div className={styles.rowLabel}>
            <IoCalendarClearOutline size={20} />
            <span className={styles.label}>{t("time")}</span>
          </div>
          <div className={styles.rowItemContent}>
            {booking?.startDateTime && salon?.timezone?.timezone
              ? formatDateTz(
                booking?.startDateTime,
                salon?.timezone?.timezone,
                "HH:mm dddd MMM DD, YYYY"
              )
              : ""}
          </div>
        </div>

        <div className={styles.contentRow}>
          <div className={styles.rowLabel}>
            <IoFileTrayFullOutline size={20} />
            <span className={styles.label}>{t("services")}</span>
          </div>
          <div className={styles.rowItemContent}>
            {booking?.bookingServices?.map((service: any) => (
              <BookingServiceCard key={service.id} item={service} />
            ))}
          </div>
        </div>

        <div className={styles.contentRowInline}>
          <div className={styles.rowLabel}>
            <AiOutlineDollarCircle size={20} />
            <span className={styles.label}>{t("sub_total")}</span>
          </div>
          <div className={styles.rowContentRight}>
            {formatCurrency(subTotal.toString(), salon?.timezone?.currency)}
          </div>
        </div>

        <div className={styles.contentRowInline}>
          <div className={styles.rowLabel}>
            <AiOutlineDollarCircle size={20} />
            <span className={styles.label}>{t("surcharge")}</span>
          </div>
          <div className={styles.rowContentRight}>
            {"+" +
              formatCurrency(
                (booking?.surcharge ?? 0).toString(),
                salon?.timezone?.currency
              )}
          </div>
        </div>

        <div className={styles.contentRowInline}>
          <div className={styles.rowLabel}>
            <AiOutlineDollarCircle size={20} />
            <span className={styles.label}>{t("promotion")}</span>
          </div>
          <div className={styles.rowContentRight}>
            {formatCurrency(
              (promotionValue ?? 0).toString(),
              salon?.timezone?.currency
            )}
          </div>
        </div>

        <div className={`${styles.contentRowInline} ${styles.flexStart}`}>
          <div className={styles.rowLabel}>
            <AiOutlineDollarCircle size={20} />
            <span className={styles.label}>{t("total")}</span>
          </div>
          <div className={styles.rowTotal}>
            <div className={styles.rowContentRight}>
              {formatCurrency(total.toString(), salon?.timezone?.currency)}
            </div>
            {booking?.status !== BookingStatus.Done && (
              <div className={styles.rowDescriptionTotal}>
                (Chi phí tham khảo)
              </div>
            )}
          </div>
        </div>

        {booking?.rate.value && (
          <div className={`${styles.contentRowInline} ${styles.flexStart}`}>
            <div className={styles.rowLabel}>
              <AiOutlineDollarCircle size={20} />
              <span className={styles.label}>{t("rating")}</span>
            </div>
            <div className={styles.rowTotal}>
              <div className={styles.rowContentRight}>
                <Stars rateValue={valueRating} marginRight={10} />
              </div>
            </div>
            <button className={styles.btnEdit} onClick={onEditRating}>
              Edit
            </button>
          </div>
        )}

        <div className={styles.contentRow}>
          <div className={styles.rowLabel}>
            <IoCalendarClearOutline size={18} />
            <span className={styles.label}>{t("status")}</span>
          </div>
          <div className={styles.rowItemStatus}>
            {t(BookingStatusNextNames[booking?.status]) ?? t("pending")}{" "}
            {bookingStatusTime && salon?.timezone?.timezone
              ? "(" +
              formatDateTz(
                bookingStatusTime?.created_at,
                salon?.timezone?.timezone,
                "DD/MM/YYYY HH:mm"
              ) +
              ")"
              : ""}
          </div>
        </div>

        {booking?.note && (
          <div className={styles.contentRow}>
            <div className={styles.rowLabel}>
              <IoDocumentTextOutline size={18} />
              <span className={styles.label}>{t("notes")}</span>
            </div>
            <div className={styles.rowItemStatus}>{booking?.note}</div>
          </div>
        )}

        {Number(booking?.status) === BookingStatus.CheckedIn &&
          bookingComments.length > 0 && (
            <div className={styles.contentRow}>
              <div className={styles.rowLabel}>
                <IoListOutline size={18} />
                <span className={styles.label}>{t("comments")}</span>
              </div>
              <div className={styles.comments}>
                {bookingComments?.map((comment: any) => (
                  <div key={comment.id} className={styles.comment}>
                    <div className={styles.commentBody}>
                      <div className={styles.commentAvatar}>
                        <img
                          className={styles.commentAvatarImg}
                          src={comment.avatar}
                          alt="avatar"
                        />
                      </div>

                      <div className={styles.commentContent}>
                        <div className={styles.commentContentText}>
                          <div className={styles.commentInfo}>
                            <div className={styles.commentAuthor}>
                              {comment?.user?.name}
                            </div>

                            <div className={styles.commentDate}>
                              {formatDate(
                                comment?.createdAt,
                                "HH:mm DD/MM/YYYY"
                              )}
                            </div>
                          </div>

                          <div className={styles.commentText}>
                            {comment?.contents?.map(
                              (content: any) =>
                                content.type === 1 && content.content
                            )}
                          </div>
                        </div>

                        <div className={styles.commentImages}>
                          {comment?.contents?.map(
                            (content: any) =>
                              content.type === 2 && (
                                <img
                                  className={styles.commentImage}
                                  key={content.id}
                                  src={`${envConfigs.IMAGE_LINK
                                    }/bookings/comments/${JSON.parse(content.content).thumbnail
                                    }`}
                                  alt="comment"
                                />
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>

      {!disabledCall && (
        <div className={styles.sectionFooter}>
          {!disabledCancel && (
            <button
              className={styles.modalButton}
              onClick={() => confirmCancelBooking(BookingStatus.Decline)}
            >
              <span>{t("decline")}</span>
            </button>
          )}

          <button className={styles.modalButtonConfirm} onClick={callSalon}>
            <span>
              <FiPhoneCall size={20} />
            </span>
          </button>

          <button className={styles.buttonBack} onClick={onBack}>
            <span>{t("back")}</span>
          </button>
        </div>
      )}
    </Wrapper>
  );
};

export default Booking;
