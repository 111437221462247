import * as actions from "stores/actions";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import images from "assets";
import { Wrapper } from "components";
import { envConfigs } from "configs/env.config";
import { useTranslation } from "react-i18next";
import {
  IoCallOutline,
  IoLogOutOutline,
  IoMailOutline,
  IoPersonOutline,
  IoLockClosedOutline,
  IoArrowBackOutline,
  IoArrowBackCircleSharp,
  IoArrowBackSharp,
} from "react-icons/io5";
import { ReduxState } from "stores/models";
import { localStorageUtils } from "utils/index";
import { modalRef } from "utils/refs";
import useStyles from "./styles";
import {
  useParams,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { UsersAPI } from "stores/apis";
import UserModel from "stores/models/user.models";

const LogoutModalContent = ({ t, styles }: any) => {
  const dispatch = useDispatch();
  const { refCode } = useParams();

  const logout = () => {
    dispatch({ type: actions.LOG_OUT });
    localStorageUtils.clear();
    window.location.href = `${envConfigs.WEB_URL}/login`;
  };

  const onCloseModal = () => modalRef?.current?.close();

  return (
    <div>
      <h6>{t("are_you_sure_logout")}?</h6>
      <div className={styles.logoutBtns}>
        <button onClick={onCloseModal} className={styles.btnNo}>
          {t("cancel")}
        </button>
        <button onClick={logout} className={styles.btnYes}>
          {t("logout")}
        </button>
      </div>
    </div>
  );
};

const User = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();
  const history = useNavigate();
  const [searchParams] = useSearchParams();

  const [state, setState] = useState({
    loading: false,
    name: searchParams.get("name") ?? "",
    newdisplayname: "",
    password: "",
    newpassword: "",
    message: "",
  });

  const handleStateChange = (
    value: string | string[] | boolean,
    field: string
  ) => setState((prevState) => ({ ...prevState, [field]: value }));

  const { salon, user } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    user: state.users.user,
  }));

  useEffect(() => {
    dispatch({ type: actions.SET_MENU, payload: 2 });
  }, []);

  const onOpenLogoutModal = () => {
    modalRef?.current?.open({
      content: <LogoutModalContent t={t} styles={styles} />,
    });
  };

  const onChangeInput = (e: any) => {
    const { name, value } = e.target;
    handleStateChange(value, name);
  };

  const saveUser = (data: any) => {
    dispatch({ type: actions.SET_USER, payload: new UserModel(data) });
  };

  const [filebase64, setFileBase64] = useState<string>("");
  const [currentFile, setCurrentFile] = useState<File>();
  const convertFile = (files: FileList | null) => {
    if (files) {
      const fileRef = files[0] || "";
      setCurrentFile(fileRef);
      const fileType: string = fileRef.type || "";
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev: any) => {
        // convert it to base64
        setFileBase64(`data:${fileType};base64,${btoa(ev.target.result)}`);
      };
    }
  };

  const Success = () => {
    const onCloseModal = () => {
      modalRef?.current?.close();
    };
    return (
      <div className={styles.confirmContent}>
        <h4>Successfully Changed Information</h4>
        <button className={styles.buttonClose} onClick={onCloseModal}>
          Close
        </button>
      </div>
    );
  };

  const openModalSuccess = () => {
    modalRef?.current?.open({
      content: <Success />,
    });
  };

  const onSaveInfo = async () => {
    if (user) {
      const response = await UsersAPI.updateUser({
        userId: user?.id,
        files: currentFile,
        name: state.name ? state.name : user.name,
        phone: user?.phone,
      });
      if (response.data.status === 200) {
        handleStateChange("", "message");
        openModalSuccess();
        saveUser(response.data.data);
      }
    }
  };

  if (!user) return null;

  return (
    <Wrapper>
      <div className={styles.content}>
        <div className={styles.arrowBackSection}>
          <IoArrowBackSharp
            size={40}
            cursor="pointer"
            onClick={() => {
              history(-1);
            }}
          />
          <div className={styles.txtAccount}>
            <h3>Account</h3>
          </div>
        </div>

        <div className={styles.sectionBody}>
          <div className={styles.firstSection}>
            <div className={styles.emailName}>
              <div className={styles.row}>
                <div className={styles.leftContent}>
                  <div className={styles.rowTitle}>
                    <IoPersonOutline size={18} />
                    <div className={styles.titleLabel}>Display Name</div>
                  </div>
                  <div className={styles.rowValue}>
                    <input
                      type="text"
                      className={styles.formControl}
                      name="name"
                      placeholder={user?.name}
                      value={state.name}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

             
            </div>
            <div className={styles.imageSection}>
              <div className={styles.rightContent}>
                <label htmlFor="file-input" className={styles.avatar}>
                  <img
                    className={styles.avatarImage}
                    src={filebase64 ? filebase64 : user?.avatarUrl}
                    alt="avatar"
                  />
                </label>
                <input
                  type="file"
                  id="file-input"
                  className={styles.fileInput}
                  onChange={(e) => convertFile(e.target.files)}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
                <div className={styles.leftContent}>
                  <div className={styles.rowTitle}>
                    <IoMailOutline size={18} />
                    <div className={styles.titleLabel}>Email</div>
                  </div>
                  <div className={styles.rowValue}>
                    <input
                      type="text"
                      className={styles.formControl}
                      name="email"
                      placeholder={user?.name}
                      value={user?.email}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

          <div className={styles.row}>
            <div className={styles.leftContent}>
              <div className={styles.rowTitle}>
                <IoCallOutline size={18} />
                <div className={styles.titleLabel}>Phone</div>
              </div>
              <div className={styles.rowValue}>
                <input
                  type="text"
                  className={styles.formControl}
                  name="phone"
                  placeholder={user?.name}
                  value={user?.phone}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.leftContent}>
              <div className={styles.rowTitle}>
                <IoLockClosedOutline size={18} />
                <div className={styles.titleLabel}>Password</div>
                <div className={styles.changePassword}>
                  <Link to={`/passwordChange`}>Change</Link>
                </div>
              </div>
              <div className={styles.rowValue}>
                <input
                  type="password"
                  className={styles.formControl}
                  name="phone"
                  placeholder={user?.name}
                  value={user?.phone}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          <div>
            <button className={styles.btnSave} onClick={onSaveInfo}>
              Update
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default User;
