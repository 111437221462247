import { envConfigs } from "configs";
import gateway, { APIMethod, parseFormData } from "./gateway";

const API_SERVER = envConfigs.SERVER_URL;

export const getUserProfile = () => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/users/profile`);
};

export const getUserDetail = (userId: number) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/users/${userId}`);
};

export const getUsers = () => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/users`);
};

export const updateUserAvatar = (userId: number, file: any) => {
  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/users/${userId}/change-avatar`,
    parseFormData({ file }),
    { "Content-Type": "multipart/form-data" }
  );
};

export const updateUserAvatars = (params: any) => {
  const { userId, ...data } = params;
  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/users/${userId}`,
    parseFormData({ data }),
    { "Content-Type": "multipart/form-data" }
  );
};

export const saveUser = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users`, params);
};

export const changePasswordUser = (userId: number, params: any) => {
  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/users/${userId}/change-password`,
    params
  );
};

export const updateUser = (params: any) => {
  const { userId, files, ...data } = params;
  const formData: any = parseFormData(data);

  if (files) {
    formData.append("files", files);
  }

  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/users/${userId}`,
    formData,
    { "Content-Type": "multipart/form-data" }
  );
};

export const deleteUser = (userId: number) => {
  return gateway.send(APIMethod.DELETE, `${API_SERVER}/users/${userId}`);
};
